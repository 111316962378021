.certificate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 101px 0 0;

  &__descr{
    max-width: 644px;
    width: 100%;
  }

  &__heading {
    font-weight: 600;
    font-size: 57px;
    line-height: 80px;
    letter-spacing: 0.01em;
    color: $black;
    margin: 0;
  }

  &__subhead {
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: $grey;
    margin: 20px 0 52px;
  }

  &__img {
    max-width: 489px;
    width: 100%;
  }
}