.pros {
  margin: 60px 0 0;
  &__line {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 40px;
  }


  &__item {
    max-width: 312px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__heading {
    margin: 30px 0 4px;
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    text-align: center;
    color: $black;
  }

  &__text {
    margin: 0;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $grey;
  }
}
