.hero {
  display: flex;
  align-items: center;
  margin-top: 73px;
  justify-content: space-around;
  
  &__description {
    max-width: 548px;
    margin:0 20px 0 112px;

    &-text {
      display: flex;
      flex-direction: column;
      // align-items: center;
      gap: 20px;
    }

    &-btns{
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin-top: 52px;
    }
  }

  &__heading{
    font-weight: 600;
    font-size: 3.563em;
    line-height: 80px;
    letter-spacing: 0.01em;
    color: $black;
    margin: 0;
  }

  &__subhead {
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: $grey;
    margin: 0;
  }

  &__image {

    &-pic {
      width: 100%;
      max-width: fit-content;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 152px;
    margin-top: 58px;

    &-desc {
      max-width: 283px;
      margin: 0;
      position: relative;
    }
    
    &-desc:nth-child(2)::before{
      content: "";
      width: 0.5px;
      height: 57px;
      background: $grey;
      position: absolute;
      left: -120px;
      top: 25%;
    }

    &-desc:nth-child(2)::after {
      content: "";
      width: 0.5px;
      height: 57px;
      background: $grey;
      position: absolute;
      right: -70px;
      top: 25%;
    }

    &-heading {
      font-weight: 600;
      font-size: 28px;
      line-height: 39px;
      color: $black;
      margin: 0;
    }

    &-text {
      font-weight: 400;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: $grey;
      margin: 12px 0 0;
    }
  }
}