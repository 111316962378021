@import "bootstrap/scss/bootstrap";

@import "swiper/scss";
@import "swiper/scss/pagination";

.articles {
  &__img {
    max-width: 498px;
    width: 100%;
    max-height: 332px;
    height: 100%;
    margin: 0 32px 0 0;
    border-radius: 25px;
    object-fit: cover;
    object-position: bottom;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 52px 0 0;
  }

  &__descr {
    max-width: 688px;
  }

  &__heading {
    font-weight: 600;
    font-size: 28px;
    line-height: 39px;
    color: $black;
    margin: 0;
  }

  &__text {
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: $grey;
    margin: 20px 0 0;
  }
}

.article__img,
.random-articles__img {
  width: 100%;
  height: 632px; /* Set the height to a fixed size */
  object-fit: cover; /* Ensures the image covers the entire area without distorting */
  border-radius: 8px; /* Optional: adds rounded corners */
}

.random-articles__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.random-articles__img {
  width: 100%;
  height: 220px; /* Set the height for the smaller images */
  object-fit: cover;
  object-position:  center;
  margin-bottom: 10px; /* Add some space between image and text */
}


.pattern {
  position: absolute;
  top: -2%;
  right: 0;
}

.breadcrumbs {
  margin-top: 98px;
}

.breadcrumb-item,
.breadcrumb-item > a {
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: $grey;
  text-decoration: none;
}

.swiper {
  margin-bottom: 124px;

  &-wrapper {
    margin-bottom: 52px;
  }
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 16px);
}
.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 12px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 12px)
  );
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
  left: 0;
  width: 100%;
}

.article__img {
  width: 100%;
  border-radius: 25px;
}
