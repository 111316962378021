.privacy-txt {
  color: gray;
  font-size: 19px;
  line-height: 28.5px;
  margin: 0;
  letter-spacing: 0.01em;
}

.privacy-head {
  color: gray;
  font-size: 19px;
  line-height: 28.5px;
  margin: 0px 0;
  letter-spacing: 0.01em;
}

.privacy-item {
  margin-top: 20px;
}

.privacy__desc {
  margin: 52px 0 124px;
}