@import "swiper/scss/navigation";

.card-item {
  display: flex;
  align-items: flex-start;
  gap: 28px; //32//
  margin: 52px 0;

  &__item {
    max-width: 282px;
    width: 100%;
  }

  &__type {
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    color: $black;
    margin: 20px 0 4px;
  }

  &__brand {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $grey;
  }
  &__img {
    width: 100%;
  }
}

.df {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.diploma {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 42px;

  &__img {
    width: 100%;
  }
}

.chem-swiper {
  position: relative;
}

.first__swiper {
  width: 100%;
  .swiperslide {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sec-swiper {
  margin: 52px 0 124px;
}

.sec-swiper > .swiper-wrapper {
  align-items: center;
}

.swiperNavPrev,
.swiperNavNext {
  top: 40%;
  position: absolute;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  z-index: 1;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.swiperNavPrev {
  left: 0;
  transform: translateX(-75px);
  background-image: url(".././img/left.svg");
}

.swiperNavNext {
  right: 0;
  transform: translateX(75px);
  background-image: url(".././img/right.svg");
}

.diploma-swiper {
  position: relative;
}
.swiperPrev,
.swiperNext {
  top: 55%;
  position: absolute;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  z-index: 1;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.swiperPrev {
  left: 0;
  transform: translateX(-75px);
  background-image: url(".././img/left.svg");
}

.swiperNext {
  right: 0;
  transform: translateX(75px);
  background-image: url(".././img/right.svg");
}

.technical-sw {
  position: relative;
}

.swiperTechPrev,
.swiperTechNext {
  top: 45%;
  position: absolute;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  z-index: 1;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.swiperTechPrev {
  left: 0;
  transform: translateX(-75px);
  background-image: url(".././img/left.svg");
}

.swiperTechNext {
  right: 0;
  transform: translateX(75px);
  background-image: url(".././img/right.svg");
}

.cleaner-sw {
  position: relative;
}

.swiperCleanPrev,
.swiperCleanNext {
  top: 45%;
  position: absolute;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  z-index: 1;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.swiperCleanPrev {
  left: 0;
  transform: translateX(-75px);
  background-image: url(".././img/left.svg");
}

.swiperCleanNext {
  right: 0;
  transform: translateX(75px);
  background-image: url(".././img/right.svg");
}

.swiperHomePrev,
.swiperHomeNext {
  top: 45%;
  position: absolute;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  z-index: 1;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.swiperHomePrev {
  left: 0;
  transform: translateX(-75px);
  background-image: url(".././img/left.svg");
}

.swiperHomeNext {
  right: 0;
  transform: translateX(75px);
  background-image: url(".././img/right.svg");
}

.swiperBlogPrev,
.swiperBlogNext {
  top: 45%;
  position: absolute;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  z-index: 1;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.swiperBlogPrev {
  left: 0;
  transform: translateX(-75px);
  background-image: url("../img/left.svg");
}

.swiperBlogNext {
  right: 0;
  transform: translateX(75px);
  background-image: url("../img/right.svg");
}

.footer-slide {
  margin: 52px 0 0 112px;
  position: relative;
  @import "swiper/scss/navigation";

  :root {
    --swiper-navigation-size: 22px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: $grey;
    top: 40%;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
    right: 50px;
    margin: -50px -40px 0 0;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
    margin: -50px 40px 0 0;
  }

  .chemicals {
    display: flex;
    align-items: flex-start;
    gap: 28px; //32//
    margin: 52px 0;

    &__item {
      max-width: 282px;
    }

    &__type {
      font-weight: 500;
      font-size: 19px;
      line-height: 23px;
      color: $black;
      margin: 20px 0 4px;
    }

    &__brand {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $grey;
    }
  }

  .diploma {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
