@media screen and (min-width: 1400px) {
  .header {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 40px;
  }
  .hero {
    max-width: 1600px;
    width: 100%;
    margin: 73px auto 0;
    justify-content: space-around;

    &__info {
      margin: 58px auto 0;
      max-width: 1600px;
    }

    &__description {
      margin: 0;
    }
  }
}

@media screen and (max-width: 1280px) {
  .swiperHomePrev {
    transform: translateX(-15px);
    // background-color: #8d8d8d26;
    backdrop-filter: blur(4px);
    border-radius: 20%;
    padding: 10px 10px;
  }
  .swiperHomeNext {
    transform: translateX(15px);
    backdrop-filter: blur(4px);
    border-radius: 20%;
    padding: 10px 10px;
  }
}

@media screen and (max-width: 1120px) {
  .heading {
    font-size: 46px !important;
  }

  .hamburger-react {
    display: block;
    z-index: 10;
  }

  .hamburger-react[aria-expanded="true"] {
    top: 16px;
  }

  .menu-icon {
    display: block;
  }
  .close-icon {
    display: block;
    z-index: 10001;
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .container {
    max-width: 822px !important;
  }

  .hero {
    align-items: stretch;
    &__description {
      max-width: 480px;
    }
    &__heading {
      font-size: 41px;
      line-height: 71px;
    }
  }

  .header {
    &__nav {
      &-list {
        display: none;
      }
      &-list::before {
        position: absolute;
        content: url(../img/pattern-menu.svg);
        top: 10px;
        left: 10px;
        display: block;
      }

      &-list.active {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        // width: 100%;
        height: 1000px;
        background: #ffffff;
        // padding: 95px 80px;
        padding: 8rem 2rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 10;
        gap: 42px;
      }

      &-item {
        font-weight: 500;
        font-size: 32px;
        line-height: 23px;
      }
    }
    &__contacts {
      margin: 0 0% 0 30%;
    }
  }

  .hero__info {
    &-desc:nth-child(2)::before {
      content: "";
      width: 0.5px;
      height: 57px;
      background: $grey;
      position: absolute;
      left: -80px;
      top: 25%;
    }

    &-desc:nth-child(2)::after {
      content: "";
      width: 0.5px;
      height: 57px;
      background: $grey;
      position: absolute;
      right: 0px;
      top: 25%;
    }
  }

  .services {
    &__descr-heading {
      font-size: 22px;
      line-height: 36px;
    }

    &__descr-text {
      font-size: 17px;
    }
  }

  .reviews {
    &__human {
      max-width: 215px;
      width: 100%;
    }
  }

  .pros {
    &__item {
      max-width: 250px;
    }

    &__heading {
      font-size: 18px;
      line-height: 32px;
    }

    &__text {
      font-size: 16px;
    }
  }

  .certificate {
    &__heading {
      font-size: 44px;
    }

    &__subhead {
      font-size: 16px;
    }
  }

  .reviews {
    &__item {
      max-width: 360px;
    }

    &__text {
      font-size: 16px;
    }

    &__heading {
      font-size: 24px;
    }
  }

  .pattern {
    top: -64px;
    right: -64px;
    width: 180px;
  }

  .pattern2 {
    right: 76px;
    top: 26px;
    width: 150px;
  }

  .btn-lbl {
    width: 290px;
  }

  .articles__img {
    max-width: 430px;
  }
  .articles__heading {
    font-size: 24px;
  }

  .articles__text {
    font-size: 16px;
  }

  .article__img {
    max-width: 790px;
    width: 100%;
  }

  .date {
    right: -52px;
  }
  .pattern-blog {
    top: -37px;
    right: 79px;
  }

  .article-text {
    font-size: 17px;
  }

  .pattern-bottom {
    max-width: 173px;
    width: 100%;
  }

  .pagination__wrap {
    grid-template-columns: 1fr;
    justify-items: center;
    margin: 42px 0 0 0;
    gap: 25px;
    max-width: 960px;
  }

  .nav-tabs {
    gap: 42px;
  }

  .tech-swiper > .swiper-wrapper.swiper-slide {
    width: 285px !important;
  }

  .chem-swiper > .swiper-wrapper.swiper-slide {
    width: 285px !important;
  }

  .chem-swiper > .swiper-wrapper {
    gap: 0 !important;
    padding: 0 0 0 60px;
  }

  .tech-swiper > .swiper-wrapper {
    gap: 0 !important;
    padding: 0 0 0 60px;
  }

  .tech-swiper > .swiper-slide-active {
    margin-left: 40px;
  }

  .clean-swiper > .swiper-wrapper {
    gap: 0 !important;
    padding: 0 0 0 60px;
  }

  .first-swiper > .swiper-wrapper {
    gap: 0 !important;
    padding: 0 0 0 60px;
  }
}

@media screen and (max-width: 820px) {
  .container {
    max-width: 580px !important;
  }
  .hero {
    flex-direction: column-reverse;
    align-items: center;

    &__image-pic {
      width: 100%;
      height: auto;
    }

    &__description {
      max-width: 100%;
      margin: 0 40px;
    }

    &__heading {
      text-align: center;
      padding: 20px 0 0;
    }

    &__subhead {
      text-align: center;
    }

    &__description-btns {
      align-items: center;
    }

    &__info {
      padding-left: 40px;

      &-desc {
        max-width: 185px;
      }

      &-desc:nth-child(2)::before {
        content: "";
        width: 0.5px;
        height: 57px;
        background: $grey;
        position: absolute;
        left: -56px;
        top: 25%;
      }

      &-desc:nth-child(2)::after {
        content: "";
        width: 0.5px;
        height: 57px;
        background: $grey;
        position: absolute;
        right: -32px;
        top: 25%;
      }

      &-text {
        font-size: 14px;
      }
    }
  }

  .about {
    flex-direction: column-reverse;
    gap: 20px;

    &__text {
      text-align: center;
    }
  }

  .services__descr-text {
    font-size: 14px;
  }

  .heading {
    text-align: center;
    font-size: 40px !important;
  }

  .subheading {
    text-align: center;
    font-size: 16px !important;
  }

  .certificate {
    flex-direction: column-reverse;

    &__descr {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__heading {
      font-size: 32px;
      line-height: 67px;
      text-align: center;
    }

    &__subhead {
      font-size: 14px;
      text-align: center;
    }
  }

  .reviews {
    justify-content: center;
    align-items: center;
    gap: 12px;

    &__item {
      max-width: 450px;
    }
  }

  .nav-justified .nav-item .nav-link {
    font-size: 15px;
  }

  .tab-heading {
    font-size: 24px;
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
  }

  .footer {
    align-items: center;
    &__items {
      display: none;
    }
  }

  .tech-swiper > .swiper-wrapper {
    gap: 0 !important;
    padding: 0 0 0 10px;
  }

  .clean-swiper > .swiper-wrapper {
    gap: 0 !important;
    padding: 0 0 0 10px;
  }

  .service-price__descr,
  .service-price__name {
    font-size: 16px;
  }

  .pattern2 {
    width: 95px;
  }

  .articles {
    &__item {
      flex-direction: column;
    }

    &__descr {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__img {
      max-width: 530px;
      width: 100%;
      margin: 0;
    }

    &__heading {
      text-align: center;
      margin: 20px 0 0;
    }

    &__text {
      text-align: center;
    }
  }

  .pattern-blog {
    top: -13px;
    right: -53px;
  }

  .date {
    right: 41px;
    top: -41px;
  }

  .pattern-bottom {
    max-width: 111px;
  }

  .pattern {
    max-width: 111px;
  }

  .first-swiper > .swiper-wrapper {
    gap: 0 !important;
    padding: 0 0 0 10px;
  }

  .diploma {
    justify-content: center;
  }

  .results-tabs {
    margin: 72px 0 0 30px;
  }

  .nav-tabs {
    gap: 28px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 502px) {
  .container {
    max-width: 302px !important;
    overflow-x: hidden;
  }
  .services {
    margin: 52px 0;
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  .visible-ph {
    display: block;
  }

  .visible-ph > svg {
    width: 25px;
    height: 25px;
  }
  .invisible-ph {
    display: none;
  }

  .hero {
    &__heading {
      font-weight: 600;
      font-size: 36px;
      line-height: 47px;
    }

    &__subhead {
      font-weight: 400;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.01em;
      text-align: left;
    }
    &__info {
      padding: 0 20px;
      align-items: flex-start;
      justify-content: space-around;

      &-desc {
        max-width: 103px;
      }

      &-heading {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }

      &-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  .header {
    margin: 16px 20px 0;
  }

  .header.sticky {
    margin: 0 1px;
    padding: 10px 20px;
    z-index: 200;
  }

  .heading {
    font-weight: 600 !important;
    font-size: 31px !important;
    line-height: 47px !important;
    text-align: left !important;
  }

  .heading.privacy {
    font-size: 32px !important;
  }
  .heading.mobile {
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 39px !important;
    text-align: left !important;
  }
  .heading.error-text {
    font-weight: 600 !important;
    font-size: 36px !important;
    line-height: 47px !important;
    text-align: center !important;
  }

  .heading.mt {
    margin: 72px 0 0;
  }

  .hero__info-desc::before,
  .hero__info-desc::after {
    display: none;
  }

  .about {
    margin: 72px 0;
    &__text {
      font-weight: 400;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.01em;
      text-align: left;
      margin-top: 8px !important;
    }
  }

  .services__descr {
    &-heading {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      margin: 16px 0 12px;
    }

    &-text {
      font-weight: 400;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.01em;
    }
  }

  .pros {
    &__line {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    &__item {
      max-width: 100%;
      margin: 0;
    }

    &__heading {
      font-weight: 600;
      font-size: 26px;
      line-height: 39px;
    }

    &__text {
      font-weight: 400;
      font-size: 19px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.01em;
    }
  }

  .reviews {
    &__heading {
      font-size: 18px;
      line-height: 29px;
    }

    &__human {
      max-width: 180px;
      width: 100%;
    }

    &__flat {
      font-size: 16px;
    }
  }

  .certificate {
    &__heading {
      font-weight: 600;
      font-size: 31px;
      line-height: 47px;
      text-align: left;
      margin: 20px 0 0;
    }

    &__subhead {
      font-weight: 400;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.01em;
      text-align: left;
      margin: 20px 0;
    }
  }

  .questions {
    margin: 72px 0 0;

    &__wrapper {
      margin: 20px 0 0;
    }
  }

  .footer.reg {
    display: none;
  }
  .footer.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0 20px 20px;
  }

  .footer-mob {
    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &-wrap > svg {
      width: 40px;
      height: 40px;
    }
    &-inner {
      display: flex;
      gap: 20px;
      align-items: center;

      &__items {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  .footer__privacy {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    max-width: 149px;
    margin: 0;
  }

  .popup__accordion > .accordion-item:first-of-type .accordion-button {
    height: inherit;
  }

  .offer-tabs {
    margin: 0 auto;
    max-width: 302px;
  }

  .tab-content.nav.nav-tabs.nav-justified {
    margin: 15px 0;
    gap: 16px;
  }

  .tab-heading {
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.01em;
    margin: 15px 0 0;
  }

  .subheading {
    text-align: left;
  }

  .card-item {
    &__img {
      max-width: 280px;
      width: 100%;
    }
    &__item {
      max-width: 280px;
      width: 100%;
    }
  }

  .swiper {
    margin-bottom: 72px;
  }
  .swiper.home-swiper {
    margin-bottom: 0;
  }

  .swiperTechNext {
    transform: translateX(11px);
  }
  .swiperTechPrev {
    transform: translateX(-11px);
  }

  // .swiper-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  // .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  //   transform: translateX(-96px);
  // }

  .card-item__item.df > .card-item__img {
    width: auto;
  }

  .swiperCleanNext {
    transform: translateX(11px);
  }
  .swiperCleanPrev {
    transform: translateX(-11px);
  }

  .pattern2 {
    display: none;
  }

  .pattern {
    top: 2rem;
    right: 9px;
    max-width: 62px;
  }

  .pattern-blog {
    top: -91px;
    right: 13px;
    max-width: 62px;
  }

  .service-price {
    padding: 20px;
  }

  .tab-content {
    margin: 0 0 72px;
  }

  .err-pic {
    width: 100%;
  }

  .articles {
    &__heading {
      font-weight: 600 !important;
      font-size: 28px !important;
      line-height: 39px !important;
      text-align: left !important;
      margin-top: 12px !important;
    }

    &__text {
      text-align: left !important;
      margin-top: 12px !important;
      font-weight: 400 !important;
      font-size: 19px !important;
      line-height: 28px !important;
      letter-spacing: 0.01em !important;
      width: 100%;
    }
  }

  .btn-lbl {
    width: 100%;
    margin-top: 12px;
  }

  .date {
    position: unset;
    margin: 16px 0;
  }

  .top-sec > .heading {
    font-weight: 600;
    font-size: 28px !important;
    line-height: 39px !important;
  }

  .article {
    margin: 0;

    &__inner {
      margin: 20px 0 72px;
    }

    &-text {
      font-weight: 400 !important;
      font-size: 19px !important;
      line-height: 28px !important;
      letter-spacing: 0.01em;
    }
  }

  .first-swiper {
    margin-top: 20px !important;
    height: auto;
  }

  .clean-swiper,
  .tech-swiper {
    height: auto;
  }

  .first-swiper > .swiper-wrapper.swiper-slide {
    width: 285px !important;
    margin: 0 0 20px !important;
  }

  .swiper-wrapper {
    margin: 0 0 40px !important;
  }

  .swiperNavNext {
    transform: translateX(11px);
  }
  .swiperNavPrev {
    transform: translateX(-11px);
  }

  .diploma {
    &__img {
      width: 100%;
      max-width: 280px;
    }
  }

  .swiperNext {
    transform: translateX(11px);
  }
  .swiperPrev {
    transform: translateX(-11px);
  }

  .sec-swiper {
    margin: 16px 0 72px !important;
    height: auto;
  }

  .results-tabs {
    margin: 20px auto 72px;
    max-width: 302px;
    width: 100%;
  }

  .pagination {
    &__wrap {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-top: 20px;
    }

    &-image > img {
      width: 100%;
    }
  }

  .popup__block {
    padding: 48px 20px 40px;
  }

  .popup-gift__block {
    padding: 48px 20px 40px;
  }

  .popup-thnks {
    &__content {
      max-width: 356px;
      padding: 85px 23px 40px;
    }
    &__title {
      font-size: 40px;
      line-height: 59px;
    }

    &__descr {
      font-size: 16px;
      line-height: 25px;
    }

    &__pattern {
      width: 120px;
      height: 78px;
    }
  }
  .random-articles__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    gap: 24px;
  }

  .swiperHomePrev {
    display: none;
  }
  .swiperHomeNext {
    display: none;
  }
}
