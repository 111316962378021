.about {
  display: flex;
  align-items: center;
  margin: 130px 0 124px;

  &__desc {
    max-width: 516px;
    width: 100%
  }

  &__text {
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: $grey;
    margin: 20px 0 0;
  }

  &__pic {
   max-width: 100%;
  }
}