.pagination {
  justify-content: center;
  align-items: center;
  margin: 52px 0 0;
  gap: 10px;
  color: $grey;
  max-width: 1216px;
  cursor: pointer;

  &__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: 30px;
    max-width: 1216px;
    margin: 52px 0 0 0;
  }

  &__prev {
    display: none;
  }

  &__next {
    display: none;
  }

  &__active {
    color: $blue;
  }

  &__image {
    max-width: 593px;
    width: 100%;
  }

  &__mini-img {
    max-width: 593px;
    width: 100%;
    border-radius: 25px;
    cursor: pointer;
  }
}

.results-tabs {
  margin: 72px auto 0;
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-image-view {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(5px);
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  max-width: 920px;
  width: 100%;
}

.full-image-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.full-image-view img {
  width: 100%;
  // max-height: 80vh;

  display: block;
  margin: 0 auto;
}

.full-image-view button {
  margin-top: 15px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
}
