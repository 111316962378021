.popup-gift {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #1a1a1b2a;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  overflow-y: auto;
  padding: 40px 0;

  &__heading {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    position: relative;
  }

  &__descr {
    margin: 0 0 52px;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: $grey;
  }

  &__accordion.accordion > .accordion-item > .accordion-header {
    border: none;
    margin: 0;
  }

  &__accordion.accordion
    > .accordion-item
    > .accordion-header
    button[aria-expanded="false"] {
    border: 1px solid $dgrey;
    border-radius: 20px;
  }

  &__accordion.accordion
    > .accordion-item
    > .accordion-header
    button[aria-expanded="true"] {
    border: none;
    border-radius: 20px;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.01em;
    padding: 14px 16px;
    width: 98%;
  }

  &__accordion.accordion
    > .accordion-item
    > .accordion-header
    > .accordion-button.collapsed {
    color: $dgrey;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.01em;
    padding: 14px 16px;
    width: 98%;
  }

  &__accordion.accordion
    > .accordion-item
    > .accordion-header
    > .accordion-button.collapsed::after {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234E4E4E'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  }

  &__accordion.accordion
    > .accordion-item
    > .accordion-collapse.collapse.show
    > .accordion-body {
    padding: 24px 0 0;
    transition: ease 0.3s;
    width: 98%;
  }

  &__accordion.accordion
    > .accordion-item
    > .accordion-collapse.collapse
    > .accordion-body {
    padding: 0;
  }

  &__block {
    max-width: 672px;

    background-color: $white;
    margin: 0 auto 40px;
    padding: 55px 72px 40px;
    border-radius: 12px;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 39px;
    margin: 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    &-item {
      width: 100%;
      position: relative;
    }

    &-label {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: $dgrey;
      transition: all 0.3s ease;
      pointer-events: none;

      &--text {
        top: 15%;
      }

      &-check {
        margin-right: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.01em;
        color: $dgrey;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 15px 0 0;
        // max-width: 460px;
        // width: 100%;
      }

      &-check::after {
        content: "";
        display: inline-block;
        height: 20px;
        width: 20px;
        border: 1px solid #4e4e4e;
        border-radius: 2px;
        vertical-align: middle;
        position: absolute;
        right: 0;
      }
    }

    &-input {
      border: 1px solid $dgrey;
      border-radius: 20px;
      width: calc(100% - 10px);
      padding: 12px 0 16px 10px;
      color: $blue;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.01em;
      outline: none;
      transition: all 0.3s ease;
      background-color: #fafafa;

      &--text {
        padding-bottom: 146px;
        margin-bottom: 32px;
      }
    }

    &-input:focus {
      border: 1px solid $blue;
    }

    &-input:focus + label,
    &-input:not(:placeholder-shown) + label {
      top: 0;
      left: 20px;
      color: $dgrey;
    }
    &-input:focus + label,
    &-input:not(:placeholder-shown):valid + label {
      top: 0;
      left: 20px;
      color: $blue;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.01em;
    }

    &-input:focus + label {
      color: $blue;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.01em;
    }

    &-span {
      padding: 5px;
      background-color: #fafafa;
      border-radius: 100px;
    }

    &-check {
      display: none;
    }

    &-check:checked + label.popup-gift__form-label-check::after {
      background-color: $blue;
      border: none;
      background-image: url(../img/checked.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    &-checkboxes {
      display: flex;
      flex-direction: column;
      border: 1px solid #4e4e4e;
      border-radius: 20px;
      padding: 12px 0 16px 16px;
      // width: 100%;
    }

    &-btn {
      margin: 0 auto;
      max-width: 528px;
      width: 100%;
      height: 64px;
      border-radius: 48px;
      background-color: $blue;
      color: $white;
      font-weight: 500;
      font-size: 19px;
      line-height: 23px;
      border: none;
      cursor: pointer;
    }

    &-btn:disabled {
      background: #33476aa5;
    }
  }
}

.cross {
  transform: translateY(-20px);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
