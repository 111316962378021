.services {
  // display: flex;
  gap: 30px;
  margin: 52px 0 124px;

  &__item {
    max-width: 386px;
    width: 100%;
  }

  &__pic {
    width: 100%;
    max-width: 385px;
    border-radius: 12px;
    aspect-ratio: 3/2;
    object-fit: cover;
  }

  &__descr {
    &-heading {
      font-weight: 600;
      font-size: 24px;
      line-height: 39px;
      color: $black;
      margin: 20px 0;
    }
    &-text {
      font-weight: 400;
      font-size: 19px;
      letter-spacing: 0.01em;
      line-height: 28px;
      margin: 0;
      color: $grey;
    }
  }
}
