.footer-slide {
  &__item {
    max-width: 488px;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 39px;
    margin: 0 0 52px;
    color: $black;
  }

  &__text {
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: $grey;
    margin: 0 0 52px 0;
  }

  &__img {
    border-radius: 12px;
    max-width: 488px;
    width: 100%;
    height: 284px;
  }
}

.random-articles {
  margin: 120px 0 160px 0;

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }

  &__item {
    max-width: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__heading {
    margin: 12px 0 14px;
    min-height: 32px;
  }

  &__text {
    margin: 0 0 12px;
  }

  &__link {
    text-decoration: none;
    color: #007aff;
  }

  &__img {
    max-width: 320px;
    width: 100%;
    border-radius: 25px;
  }
}
